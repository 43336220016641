import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationsShipperService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  atualizaPerfil(perfil: any) {
    return this.http
      .put(
        `${environment.API_BACK}/usuario/atualiza-usuario`,
        perfil,
        this.httpOptions
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getTypeUser(obj: any) {
    let params = new HttpParams();
    if (obj.id != undefined) {
      params = params.append('id', obj.id);
    } else {
      params = params.append('size', String(obj.size));
      params = params.append('page', String(obj.page));
    }

    let httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/tipo-usuario`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getUser(obj: any) {
    let params = new HttpParams();
    if (obj.id != undefined) {
      params = params.append('id', obj.id);
    } else {
      params = params.append('size', String(obj.size));
      params = params.append('page', String(obj.page));
    }

    let httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/usuario`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getUserById(id: any) {
    let params = new HttpParams();

    params = params.append('id', id);

    let httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/usuario`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  createUser(obj: any) {
    return this.http.post<any>(`${environment.API_BACK}/usuario`, obj).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }
  getShipperByUser(obj: any, filtro: any) {
    const usuarioQuery = obj?.usuario ? `&usuario=${obj.usuario}` : '';
    const url = `${environment.API_FILTER}/gestao-usuario?size=999${usuarioQuery}`;
    return this.http
      .get<any>(url)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  createUserManagement(dto: any) {
    return this.http.post<any>(`${environment.API_BACK}/gestao`, dto).pipe(
      first(),
      map((dados) => {
        return dados;
      }),
      catchError((error) => {
        throw new Error(error);
      })
    );
  }

  getShippers() {
    return this.http
      .get<any>(`${environment.API_FILTER}/empresa/empresas`)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getCompany(id: string|number) {
    return this.http
      .get<any>(`${environment.API_FILTER}/cliente?id=${id}`)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          throw new Error(error);
        })
      );
  }

  getTotalOfferts(){
    return this.http
    .get<any>(`${environment.API_FILTER}/cliente/total-ofertas`)
    .pipe(
      first(),
      map((dados) => {
        return dados;
      }),
      catchError((error) => {
        throw new Error(error);
      })
    );
  }
}
