<mat-card class="container-recipient card-container" *ngIf="loaded; else isLoading">
  <mat-card-content>
    <h6 class="mt-2">Meus destinatários</h6>

    <div class="d-flex mt-4">
      <div class="flex-grow-1">
        <a mat-flat-button color="primary" class="btn-grey" [routerLink]="[newRecipient]" style="margin-right: 20px;" id="btn-new-recipient"
          ><i class="ri-add-line"></i> Novo
        </a>
        <button
        mat-flat-button
        [matMenuTriggerFor]="menu"
        class="btn-grey"
        id="btn-import-recipient"
      >
        Importar destinatários
        <i class="ri-arrow-down-s-line"></i>
      </button>
      <mat-menu #menu="matMenu">
        <a
          mat-menu-item
          class="dropdown-button-item"
          *ngFor="let action of optionsRecipient"
          (click)="redirectActionsBtnImport(action)"
          id="btn-import-recipient-options"
          >{{ action }}</a
        >
      </mat-menu>
      </div>
      <div class="d-flex flex-row-reverse">
        <form
          class="select-form d-flex"
          [formGroup]="searchForm"
          (keyup)="submitForm()"
        >
          <div class="form-group position-relative search">
            <input
              matInput
              id="search"
              name="search"
              formControlName="search"
              type="text"
              class="form-control pe-5 input-data w-100"
              placeholder="Buscar por documento"
            />
            <i
              class="ri-search-line position-absolute end-0 my-1 mx-2 text-center"
              style="top: 5px; cursor: pointer;" id="btn-search"
            ></i>
          </div>
        </form>
      </div>
    </div>

    <div class="mt-2 mat-table-container">
      <table mat-table [dataSource]="dataSource" class="mat-table mt-4">
        <caption hidden>
          Tabela destinatários
        </caption>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-recipient-name">
            {{ headerTable.lbName }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="document">
          <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-recipient-document">
            {{ headerTable.lbDocument }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.document }}
          </td>
        </ng-container>

        <ng-container matColumnDef="uf">
          <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-recipient-province">
            {{ headerTable.lbUf }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.address.province }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-head-color column-actions" id="header-table-recipient-actions"
          >
            {{ headerTable.lbActions }}
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="table-row">
            <button
              mat-button
              [matMenuTriggerFor]="menu"
              class="dropdown-button"
              id="btn-actions-recipient-table-{{ i }}"
            >
              {{ headerTable.lbActions }}
              <i class="ri-arrow-down-s-line"></i>
            </button>
            <mat-menu #menu="matMenu">
              <a
                mat-menu-item
                class="dropdown-button-item"
                *ngFor="let action of options; let ind = index"
                (click)="redirect(action, element)"
                id="btn-actions-recipient-table-options-{{ i }}-{{ ind }}"
              >
                {{ action }}
              </a>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" (dblclick)="redirectDetails(row)" id="row-table-recipient-{{i}}"></tr>
      </table>
    </div>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
      (page)="handlePageEvent($event)"
      [length]="totalElements"
      [pageIndex]="page"
      [pageSize]="size"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>

<ng-template #isLoading>
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</ng-template>
