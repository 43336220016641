<mat-card class="card-top card-container" *ngIf="loaded; else isLoading">
  <mat-card-content>
    <h6>Minhas empresas</h6>
    <div class="d-flex mt-4">
      <div *ngIf="isAdmin" style="margin-right: 20px;">
        <a [routerLink]="[newCompany]" mat-flat-button class="btn-grey" id="btn-new-company">
          <i class="ri-add-line"></i> Novo
        </a>
      </div>
      <div class="flex-grow-1">
        <div *ngIf="isAdmin">
          <button
            mat-flat-button
            [matMenuTriggerFor]="menu"
            class="btn-grey"
            id="btn-import-companies"
          >
            Importar empresas
            <i class="ri-arrow-down-s-line"></i>
          </button>
          <mat-menu #menu="matMenu">
            <a
              mat-menu-item
              class="dropdown-button-item"
              *ngFor="let action of optionsCSV"
              (click)="redirectActionsCSV(action)"
              id="btn-options-csv"
              >{{ action }}</a
            >
          </mat-menu>
        </div>
      </div>
      <div style="margin-right: 5px">
        <div fxLayout="row">
          <div fxLayout="column">
            <p id="label-filter">Filtrar por:</p>
          </div>
          <div fxLayout="column" style="margin-left: 10px">
            <div fxLayout="row">
              <div fxLayout="column">
                <div>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="check-active"
                    value=""
                    id="check-active"
                    (change)="filterStatus($event.target)"
                  />
                  <label for="check-active" style="margin-left: 5px" id="label-filter-active">
                    Ativo</label
                  ><br />
                </div>
              </div>
              <div fxLayout="column" style="margin-left: 10px">
                <div>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="check-active"
                    value=""
                    id="check-inactive"
                    (change)="filterStatus($event.target)"
                  />
                  <label for="check-inactive" style="margin-left: 5px" id="label-filter-inactive">
                    Inativo</label
                  ><br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mat-table-container">
      <table mat-table [dataSource]="dataSource" class="mat-table mt-4">
        <caption hidden>
          Tabela minhas empresas
        </caption>
        <ng-container matColumnDef="corporateName">
          <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-my-companies-corporateName">
            {{ headerTable.lbCorporateName }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.corporateName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="cnpj">
          <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-my-companies-cnpj">
            {{ headerTable.lbCnpj }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.cnpj }}
          </td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-my-companies-address">
            {{ headerTable.lbAddress }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.address }}
          </td>
        </ng-container>

        <ng-container matColumnDef="productType">
          <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-my-companies-productType">
            {{ headerTable.lbProductType }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            <span *ngIf="element.productType">{{ element.productType }}</span>
            <span *ngIf="!element.productType">Não informado</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-my-companies-status">
            {{ headerTable.lbStatus }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            <label
              for=""
              [ngClass]="{
                'label-active': element.status === 'Ativo',
                'label-inactive': element.status === 'Inativo'
              }"
            >
              {{ element.status }}
            </label>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-head-color column-actions"
          >
            {{ headerTable.lbActions }}
          </th>
          <td mat-cell *matCellDef="let element; let index = index" class="table-row">
            <button mat-button [matMenuTriggerFor]="menu" class="dropdown-button" id="btn-menu-my-companies-{{index}}">
              {{ headerTable.lbActions }}
              <i class="ri-arrow-down-s-line"></i>
            </button>
            <mat-menu #menu="matMenu">
              <div *ngIf="isAdmin">
                <a
                  mat-menu-item
                  class="dropdown-button-item"
                  *ngFor="let action of element.actions; let i = index"
                  (click)="redirect(action, element)"
                  id="btn-options-my-companies-{{ i }}"
                >
                  {{ action }}
                </a>
              </div>
              <div *ngIf="!isAdmin">
                <a mat-menu-item class="dropdown-button-item"> - </a>
              </div>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" id="row-my-companies-{{i}}"></tr>
      </table>
    </div>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
      (page)="handlePageEvent($event)"
      [length]="totalElements"
      [pageIndex]="page"
      [pageSize]="size"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>

<ng-template #isLoading>
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</ng-template>
