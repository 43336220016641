<mat-card class="container-users card-container">
  <mat-card-content>
    <h6>Tipos de usuário</h6>
    <div class="mat-table-container">
      <table mat-table [dataSource]="dataSource" class="mat-table mt-4">
        <caption hidden>
          Tabela tipo de usuário
        </caption>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-user-type-type">
            {{ headerTable.lbName }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.tipo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-user-type-description">
            {{ headerTable.lbDescription }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.descricao }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let index = index" id="row-table-user-type-{{index}}"></tr>
      </table>
    </div>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
      (page)="handlePageEvent($event)"
      [length]="totalElements"
      [pageSize]="size"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>
