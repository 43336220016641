<div>
  <div class="container-logo">
    <div fxLayout="column">
      <img
        src="assets/img/svg/logo-plataforma-de-frete-medium-green.svg"
        alt=""
        width="250px"
        class="logo"
      />
    </div>
    <div class="logo-senior-topo">
      <img
        src="assets/img/svg/logo-senior.svg"
        alt=""
        width="120px"
        class="logo"
      />
    </div>
  </div>
  <div class="container-title">
    <mat-card class="card-details" style="padding-bottom: 1rem;">
      <mat-card-content>
        <div class="line-title">
          <h3 class="color-title">Ordem de Coleta</h3>
          <div class="no-print container-print">
            <i class="ri-printer-line icon-print" (click)="print()"></i>
          </div>
        </div>
        <div *ngIf="isContract; else spot">
          <mat-card class="card-container">
            <mat-card-content>
              <h6>Informações da Carga</h6>
              <div fxLayout="row" fxLayoutAlign="space-between" class="mt-3">
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/tag.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column" fxFlex>
                      <p class="informations-title">
                        {{ labels.lbNumerOfertaContrato }}
                      </p>
                      <p class="informations-content">
                        {{ detailsOfOffer?.numeroOfertaContrato }}
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/tag.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbNumeroPedido }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.numeroPedido
                            ? detailsOfOffer?.numeroPedido
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/tag.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbNumeroOrdemColeta }}
                      </p>
                      <p class="informations-content">
                        {{ detailsOfOffer?.numeroOrdemColeta }}
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/map-location-dot.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbOrigem }}
                      </p>
                      <p
                        *ngIf="
                          detailsOfOffer?.origemCidade && detailsOfOffer?.origemUf
                        "
                        class="informations-content"
                      >
                        {{ detailsOfOffer.origemCidade }},
                        {{ detailsOfOffer.origemUf }}
                      </p>
                      <p
                        *ngIf="
                          !detailsOfOffer?.origemCidade &&
                          !detailsOfOffer?.origemUf
                        "
                        class="informations-content"
                      >
                        Não informado
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between" class="mt-3">
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/map-location-dot.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column" fxFlex>
                      <p class="informations-title">
                        {{ labels.lbDestino }}
                      </p>
                      <p
                        *ngIf="
                          detailsOfOffer?.destinoCidade &&
                          detailsOfOffer?.destinoUf
                        "
                        class="informations-content"
                      >
                        {{ detailsOfOffer.destinoCidade }},
                        {{ detailsOfOffer.destinoUf }}
                      </p>
                      <p
                        *ngIf="
                          !detailsOfOffer?.destinoCidade &&
                          !detailsOfOffer?.destinoUf
                        "
                        class="informations-content"
                      >
                        Não informado
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/calendar-day.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbDataEmissaoColeta }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.dataEmissaoColeta
                            ? detailsOfOffer.dataEmissaoColeta
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/calendar-clock.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbDataPrevistaColeta }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.dataPrevistaColeta
                            ? detailsOfOffer.dataPrevistaColeta
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/calendar-check.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbDataPrevistaEntrega }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.dataPrevistaEntrega
                            ? detailsOfOffer.dataPrevistaEntrega
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start" class="mt-3">
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/circle-dollar.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column" fxFlex>
                      <p class="informations-title">
                        {{ labels.lbTotalCarga }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.valorTotalCarga
                            ? (detailsOfOffer.valorTotalCarga | currency : "BRL")
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/calculator.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbTotalPesoBruto }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.valorTotalPesoBruto
                            ? detailsOfOffer.valorTotalPesoBruto + " Kg"
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/calculator.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbTotalCubagem }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.valorTotalCubagem
                            ? detailsOfOffer.valorTotalCubagem
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
  
          <mat-card class="card-container mt-4">
            <mat-card-content>
              <h6>Informações da Transportadora</h6>
              <div fxLayout="row" fxLayoutAlign="start" class="mt-3">
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/steering-fill.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column" fxFlex>
                      <p class="informations-title">
                        {{ labels.lbNomeMotorista }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.nomeMotoristaTransportadora
                            ? detailsOfOffer?.nomeMotoristaTransportadora
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/license-plate-icon.svg"
                        alt=""
                        width="25px"
                        style="margin-top: 10px"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbPlacaVeiculo }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.placaVeiculoTransportadora
                            ? detailsOfOffer?.placaVeiculoTransportadora
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/file-lines.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbDocumento }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.cnpjTransportadora
                            ? (detailsOfOffer?.cnpjTransportadora | cnpj)
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/building.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbRazaoSocial }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.razaoSocialTransportadora
                            ? detailsOfOffer?.razaoSocialTransportadora
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
  
          <mat-card class="card-container mt-4">
            <mat-card-content>
              <h6>Informações do Destinatário</h6>
              <div fxLayout="row" fxLayoutAlign="space-between" class="mt-3">
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/file-lines.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column" fxFlex>
                      <p class="informations-title">
                        {{ labels.lbDocumento }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.destinatarioDocumento
                            ? (detailsOfOffer.destinatarioDocumento | cnpj)
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/building.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbRazaoSocial }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.destinatarioNome
                            ? detailsOfOffer.destinatarioNome
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/map-location-dot.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbCep }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.origemCep
                            ? detailsOfOffer.origemCep
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/map-location-dot.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbEndereco }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.destinoLogradouro
                            ? endereco
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start" class="mt-3">
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/map-location-dot.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column" fxFlex>
                      <p class="informations-title">
                        {{ labels.lbBairro }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.destinoBairro
                            ? detailsOfOffer.destinoBairro
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/map-location-dot.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbCidade }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.destinoCidade
                            ? detailsOfOffer.destinoCidade
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="25">
                  <div fxLayout="row">
                    <div fxLayout="column" style="margin-right: 5px">
                      <img
                        src="assets/img/svg/icons/map-location-dot.svg"
                        alt=""
                        width="25px"
                        class="icon-top"
                      />
                    </div>
                    <div fxLayout="column">
                      <p class="informations-title">
                        {{ labels.lbEstado }}
                      </p>
                      <p class="informations-content">
                        {{
                          detailsOfOffer?.destinoUf
                            ? detailsOfOffer.destinoUf
                            : "Não informado"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="align-footer">
    <div class="mt-4 no-print-bottom">
      <button mat-flat-button color="primary" (click)="print()">Imprimir</button>
    </div>

    <div class="item footer">
      <div>
        <p class="access-platform">
          Em caso de dúvidas, acesse a plataforma clicando aqui.
        </p>
        <h6>
          <a href="https://plataformadefretes.com.br">https://plataformadefretes.com.br</a>
        </h6>

        <img
          src="https://gko-leilao-frete.s3.amazonaws.com/email/imagens/logo-senior.png"
          alt="logo senior"
        />
      </div>
    </div>
  </div>
</div>

<ng-template #spot>
  <mat-card class="card-container">
    <mat-card-content>
      <h6>Informações Gerais</h6>
      <div fxLayout="row" fxLayoutAlign="space-between" class="mt-3">
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/building.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column" fxFlex>
              <p class="informations-title">
                {{ labels.lbEmbarcador }}
              </p>
              <p class="informations-content">
                {{ detailsOfOfferSpot?.nomeEmbarcador }} -
                {{ cpfOrCnpjMask(detailsOfOfferSpot.cnpjEmbarcador) }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/alarm-clock.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbTipoOferta }}
              </p>
              <p class="informations-content">
                {{ detailsOfOfferSpot.tipoOferta }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/circle-dollar.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbValorOferta }}
              </p>
              <p class="informations-content">
                {{ detailsOfOfferSpot?.valorOferta ? (detailsOfOfferSpot.valorOferta | currency) : "Não informado" }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/file-lines.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbCertificacoes }}
              </p>
              <div *ngIf="detailsOfOfferSpot?.certificacoes" class="informations-content">
                <ul *ngFor="let certification of detailsOfOfferSpot.certificacoes">
                  <li>
                    <div style="margin-top: 20px;">
                      <div class="circle"></div>
                      <p style="margin-left: 15px" class="certifications">{{
                        certification.replaceAll("_", " ")
                      }}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div *ngIf="!detailsOfOfferSpot.certificacoes" class="informations-content">
                <span>Não informado </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between" class="mt-3">
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/map-location-dot.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column" fxFlex>
              <p class="informations-title">
                {{ labels.lbRastreamentoVeiculo }}
              </p>
              <p class="informations-content"
              >
              {{
                detailsOfOfferSpot?.rastreamentoVeiculo
                  ? 'Sim'
                  : "Não informado"
              }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="card-container mt-4">
    <mat-card-content>
      <h6>Informações da Carga</h6>
      <div fxLayout="row" fxLayoutAlign="space-between" class="mt-3">
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/tag.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column" fxFlex>
              <p class="informations-title">
                {{ labels.lbNumeroOfertaSpot }}
              </p>
              <p class="informations-content">
                {{ detailsOfOfferSpot?.numeroOferta ? detailsOfOfferSpot?.numeroOferta : 'Não informado' }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/tag.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbNumeroOrdemColeta }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.ordemColeta
                    ? detailsOfOfferSpot?.ordemColeta
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/map-location-dot.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbOrigem }}
              </p>
              <p *ngIf="detailsOfOfferSpot?.origem?.cidade && detailsOfOfferSpot?.origem?.uf" class="informations-content">
                {{ detailsOfOfferSpot?.origem.cidade }}, {{ detailsOfOfferSpot?.origem.uf }}
              </p>
              <p *ngIf="!detailsOfOfferSpot?.origem?.cidade && !detailsOfOfferSpot?.origem?.uf" class="informations-content">
                Não informado
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/map-location-dot.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbDestino }}
              </p>
              <p *ngIf="detailsOfOfferSpot?.destino?.cidade && detailsOfOfferSpot?.destino?.uf" class="informations-content">
                {{ detailsOfOfferSpot?.destino.cidade }}, {{ detailsOfOfferSpot?.destino.uf }}
              </p>
              <p *ngIf="!detailsOfOfferSpot?.destino?.cidade && !detailsOfOfferSpot?.destino?.uf" class="informations-content">
                Não informado
              </p>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start" class="mt-3">
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/calendar-clock.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column" fxFlex>
              <p class="informations-title">
                {{ labels.lbDataPrevistaColeta }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.dataPrevisaoColeta
                    ? formatDate(detailsOfOfferSpot?.dataPrevisaoColeta)
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/calendar-check.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbDataPrevistaEntrega }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.dataPrevistaEntrega
                    ? formatDate(detailsOfOfferSpot?.dataPrevistaEntrega)
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/barcode.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbTipoProduto }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.tipoProduto
                    ? detailsOfOfferSpot?.tipoProduto.replaceAll("_", " ")
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/circle-dollar.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbTotalCarga }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.valorTotalCarga
                    ? (detailsOfOfferSpot?.valorTotalCarga | currency : "BRL")
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start" class="mt-3">
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/calculator.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column" fxFlex>
              <p class="informations-title">
                {{ labels.lbTotalPesoBruto }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.valorTotalPesoBruto
                    ? detailsOfOfferSpot?.valorTotalPesoBruto + " Kg"
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/boxes.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbVolume }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.volume
                    ? detailsOfOfferSpot?.volume
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/truck.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbTipoCarga }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.tipoDeCarga?.length > 0
                    ? detailsOfOfferSpot?.tipoDeCarga
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/box.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbTipoEmbalagem }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.tipoEmbalagem
                    ? detailsOfOfferSpot?.tipoEmbalagem
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start" class="mt-3">
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/box-volume.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column" fxFlex>
              <p class="informations-title">
                {{ labels.lbAltura }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.altura 
                    ? detailsOfOfferSpot?.altura + 'cm'
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/boxe-volume.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbLargura }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.largura
                    ? detailsOfOfferSpot?.largura  + 'cm'
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/box-volume.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbComprimento }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.comprimento
                    ? detailsOfOfferSpot?.comprimento + 'cm'
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/box-volume.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbCubagem }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.cubagem
                    ? detailsOfOfferSpot?.cubagem + 'm³'
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start" class="mt-3">
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/tag.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column" fxFlex>
              <p class="informations-title">
                {{ labels.lbNumeroPedido }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.numeroPedido?.length > 0
                    ? detailsOfOfferSpot?.numeroPedido
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/tag.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbNumeroNota }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.numeroNota?.length > 0
                    ? detailsOfOfferSpot?.numeroNota
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="card-container mt-4">
    <mat-card-content>
      <h6>Informações da Transportadora</h6>
      <div fxLayout="row" fxLayoutAlign="start" class="mt-3">
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/building.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column" fxFlex>
              <p class="informations-title">
                {{ labels.lbRazaoSocial }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.nomeTransportadora
                    ? detailsOfOfferSpot?.nomeTransportadora
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/file-lines.svg"
                alt=""
                width="25px"
                style="margin-top: 10px"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbDocumento }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.cnpjTransportadora
                    ? (detailsOfOfferSpot?.cnpjTransportadora | cnpj)
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/phone-line.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbTelefone }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.telefoneTransportadora
                    ? detailsOfOfferSpot?.telefoneTransportadora
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/mail-line.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbEmail }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.emailTransportadora
                    ? detailsOfOfferSpot?.emailTransportadora
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="card-container mt-4">
    <mat-card-content>
      <h6>Informações do Destinatário</h6>
      <div fxLayout="row" fxLayoutAlign="start" class="mt-3">
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/building.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column" fxFlex>
              <p class="informations-title">
                {{ labels.lbRazaoSocial }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.nomeDestinatario
                    ? detailsOfOfferSpot.nomeDestinatario
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/file-lines.svg"
                alt=""
                width="25px"
                style="margin-top: 10px"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbDocumento }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.documentoDestinatario
                    ? (detailsOfOfferSpot?.documentoDestinatario | cnpj)
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/map-location-dot.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbCep }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.destino.cep
                    ? cepMask(detailsOfOfferSpot?.destino?.cep)
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/map-location-dot.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbEndereco }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.destino?.logradouro
                    ? detailsOfOfferSpot?.destino?.logradouro
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start" class="mt-3">
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/map-location-dot.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column" fxFlex>
              <p class="informations-title">
                {{ labels.lbBairro }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.destino?.bairro
                    ? detailsOfOfferSpot?.destino?.bairro
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/map-location-dot.svg"
                alt=""
                width="25px"
                style="margin-top: 10px"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbCidade }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.destino?.cidade
                    ? detailsOfOfferSpot?.destino?.cidade
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row">
            <div fxLayout="column" style="margin-right: 5px">
              <img
                src="assets/img/svg/icons/map-location-dot.svg"
                alt=""
                width="25px"
                class="icon-top"
              />
            </div>
            <div fxLayout="column">
              <p class="informations-title">
                {{ labels.lbEstado }}
              </p>
              <p class="informations-content">
                {{
                  detailsOfOfferSpot?.destino?.uf
                    ? detailsOfOfferSpot?.destino?.uf
                    : "Não informado"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>