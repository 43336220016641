<div class="password-container">
  <mat-dialog-title> <h6 class="mt-2">Alteração de senha</h6></mat-dialog-title>
  <mat-dialog-content>
    <form class="w-100" [formGroup]="redefinePasswordForm">
      <div class="form-group position-relative w-100">
        <label for="currentPassword" class="label-input form-label required" id="label-dialog-redefine-password-current-password"
          >Senha atual
        </label>
        <input
          #currentPasswordRef
          type="password"
          id="currentPassword"
          class="input-data form-control"
          name="currentPassword"
          matInput
          placeholder="Digite sua senha atual"
          formControlName="currentPassword"
          maxlength="30"
          required
          [ngClass]="{
            'is-invalid':
            redefinePasswordForm.get('currentPassword')?.invalid &&
            redefinePasswordForm.get('currentPassword')?.touched,
            'is-valid':
            redefinePasswordForm.get('currentPassword')?.value?.length > 0  ,
          }"
        />
        <span
          (click)="visiblePassword('currentPassword')"
          class="btn btn-sm position-absolute top-0 end-0 my-1 mx-2 text-center aling-eyes"
        >
          <i *ngIf="!this.visibleCurrentPassword" class="ri-eye-close-fill" id="hidden-current-password"></i>
          <i *ngIf="this.visibleCurrentPassword" class="ri-eye-fill" id="visible-current-password"></i>
        </span>
        <div
          *ngIf="
            redefinePasswordForm.get('currentPassword')?.invalid &&
            redefinePasswordForm.get('currentPassword')?.touched
          "
          class="invalid-feedback label-mandatory"
          id="invalid-feedback-dialog-redefine-password-current-password"
        >
          Campo obrigatório*
        </div>
      </div>
      <div class="form-group mt-2 position-relative w-100">
        <label for="newPassword" class="label-input form-label required" id="label-dialog-redefine-password-new-password"
          >Informe sua nova senha
        </label>
        <input
          #newPasswordRef
          type="password"
          id="newPassword"
          class="input-data form-control"
          name="newPassword"
          placeholder="Digite sua nova senha"
          formControlName="newPassword"
          required
          [ngClass]="{
            'is-invalid':
            redefinePasswordForm.get('newPassword')?.invalid &&
            redefinePasswordForm.get('newPassword')?.touched || (redefinePasswordForm.get('newPassword')?.value == redefinePasswordForm.get('currentPassword')?.value && redefinePasswordForm.get('newPassword')?.value?.length > 0 ) || ((!validLength || !validUpper || !validLower || !validNumber || !validSpecialCharacter) && redefinePasswordForm.get('newPassword')?.value?.length > 0),
            'is-valid':
            redefinePasswordForm.get('newPassword')?.value?.length > 0 && redefinePasswordForm.get('newPassword')?.value != redefinePasswordForm.get('currentPassword')?.value,
          }"
        />
        <span
          (click)="visiblePassword('newPassword')"
          class="btn btn-sm position-absolute top-1 end-0 my-1 mx-2 text-center aling-eyes"
        >
          <i *ngIf="!this.visibleNewPassword" class="ri-eye-close-fill" id="hidden-new-password"></i>
          <i *ngIf="this.visibleNewPassword" class="ri-eye-fill" id="visible-new-password"></i>
        </span>
        <div
          *ngIf="
            redefinePasswordForm.get('newPassword')?.invalid &&
            redefinePasswordForm.get('newPassword')?.touched && redefinePasswordForm.get('newPassword')?.value?.length == 0
          "
          class="invalid-feedback label-mandatory"
          id="invalid-feedback-dialog-redefine-password-new-password-required"
        >
          Campo obrigatório*
        </div>
        <div
          class="label-mandatory"
          [hidden]="
            redefinePasswordForm.get('newPassword')?.value == '' || redefinePasswordForm.get('newPassword')?.value != redefinePasswordForm.get('currentPassword')?.value
          "
          style="
            margin-top: 0.25rem !important;
            color: var(--bs-form-invalid-color) !important;
          "
          id="invalid-feedback-dialog-redefine-password-new-password"
        >
          A nova senha não pode ser igual à senha atual!
        </div>
      </div>
      <div class="form-group mt-2 position-relative w-100">
        <label for="password" class="label-input form-label required" id="label-dialog-redefine-password-confirm-new-password"
          >Confirme sua nova senha
        </label>
        <input
          #confirmNewPasswordRef
          type="password"
          id="confirmNewPassword"
          class="input-data form-control"
          name="confirmNewPassword"
          matInput
          placeholder="Repita sua nova senha"
          formControlName="confirmNewPassword"
          required
          [ngClass]="{
            'is-invalid':
              (redefinePasswordForm.get('confirmNewPassword')?.invalid &&
              redefinePasswordForm.get('confirmNewPassword')?.touched) || (redefinePasswordForm.get('confirmNewPassword')?.value?.length > 0 && !confirmPasswordValue),
            'is-valid':
              redefinePasswordForm.get('confirmNewPassword')?.value?.length > 0 && confirmPasswordValue
          }"
        />
        <span
          (click)="visiblePassword('confirmNewPassword')"
          class="btn btn-sm position-absolute top-1 end-0 my-1 mx-2 text-center aling-eyes"
        >
          <i *ngIf="!this.visibleConfirmPassword" class="ri-eye-close-fill" id="hidden-confirm-new-password"></i>
          <i *ngIf="this.visibleConfirmPassword" class="ri-eye-fill" id="visible-confirm-new-password"></i>
        </span>
        <div
          *ngIf="
            redefinePasswordForm.get('confirmNewPassword')?.invalid &&
            redefinePasswordForm.get('confirmNewPassword')?.touched
          "
          class="invalid-feedback label-mandatory"
          id="invalid-feedback-dialog-redefine-password-confirm-new-password-required"
        >
          Campo obrigatório*
        </div>
        <div
          class="diferent-passwords label-mandatory"
          [hidden]="(redefinePasswordForm.get('confirmNewPassword')?.value?.length > 0 && confirmPasswordValue) || redefinePasswordForm.get('confirmNewPassword')?.value?.length == 0"
          id="invalid-feedback-dialog-redefine-password-confirm-new-password"
        >
          As senhas estão diferentes!
        </div>
      </div>
    </form>

    <p class="text-password">Sua nova senha deve conter:</p>

    <div>
      <p>
        <i
          *ngIf="emptyNewPassword || (!emptyNewPassword && validLength)"
          class="ri-checkbox-circle-fill icon"
          [ngClass]="{
            'check-none': emptyNewPassword,
            'check-yes': !emptyNewPassword && validLength
          }"
          id="check-length-password"
        ></i
        ><i
          class="ri-close-circle-fill icon check-no"
          *ngIf="!emptyNewPassword && !validLength"
        ></i
        >Entre 8 e 30 caracteres
      </p>
      <p>
        <i
          *ngIf="
            emptyNewPassword || (!emptyNewPassword && validUpper && validLower)
          "
          class="ri-checkbox-circle-fill icon"
          [ngClass]="{
            'check-none': emptyNewPassword,
            'check-yes': !emptyNewPassword && validUpper && validLower
          }"
          id="check-upper-lower-password"
        ></i
        ><i
          class="ri-close-circle-fill icon check-no"
          *ngIf="!emptyNewPassword && (!validUpper || !validLower)"
        ></i
        >Letras maiúsculas e minúsculas
      </p>
      <p>
        <i
          *ngIf="emptyNewPassword || (!emptyNewPassword && validNumber)"
          class="ri-checkbox-circle-fill icon"
          [ngClass]="{
            'check-none': emptyNewPassword,
            'check-yes': !emptyNewPassword && validNumber
          }"
          id="check-number-password"
        ></i
        ><i
          class="ri-close-circle-fill icon check-no"
          *ngIf="!emptyNewPassword && !validNumber"
        ></i
        >Pelo menos um número
      </p>
      <p>
        <i
          *ngIf="
            emptyNewPassword || (!emptyNewPassword && validSpecialCharacter)
          "
          class="ri-checkbox-circle-fill icon"
          [ngClass]="{
            'check-none': emptyNewPassword,
            'check-yes': !emptyNewPassword && validSpecialCharacter
          }"
          id="check-special-character-password"
        ></i
        ><i
          class="ri-close-circle-fill icon check-no"
          *ngIf="!emptyNewPassword && !validSpecialCharacter"
        ></i
        >Pelo menos um caractere especial
      </p>
    </div>
  </mat-dialog-content>
  <div class="d-flex flex-row-reverse">
    <div mat-dialog-actions>
      <button
        mat-flat-button
        mat-dialog-close
        class="btn-grey"
        (click)="close()"
        id="btn-close-dialog-redefine-password"
      >
        voltar
      </button>
      <button
        mat-flat-button
        class="btn-green"
        (click)="updatePassword()"
        id="btn-update-password-dialog-redefine-password"
      >
        Alterar senha
      </button>
    </div>
  </div>
</div>
