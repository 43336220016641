import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SortUtil } from 'src/app/utils/sortUtil';
@Injectable({
  providedIn: 'root',
})
export class MyInvitationsService {
  constructor(private http: HttpClient, private sortUtil: SortUtil) { }


  getConvites(obj: any) {
    let params = new HttpParams();
    if (obj.id != undefined) {
      params = params.append('id', obj.id);
    } else {
      params = params.append('size', String(obj.size));
      params = params.append('page', String(obj.page));
    }
    if (obj.fantasyNameCarrier != null) {
      params = params.append('nomeFantasia', obj.fantasyNameCarrier);
    }
    if (obj.statusCarrier != null) {
      params = params.append('statusTransportadora', obj.statusCarrier);
    }

    if (obj.ordenar === true) {
      params = params.append('sort', this.sortUtil.order('dataUltimoEnvio', 'desc'));
    }


    let httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/convite`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  reenvioConvite(idConvite: any, tokenRecaptcha: string) {
    const headers = new HttpHeaders();
    let formData = new FormData()
    formData.append("conviteId", idConvite);
    formData.append("tokenRecaptcha", tokenRecaptcha);

    return this.http
      .post<any>(
        `${environment.API_BACK}/convite/reenviar`,
        formData, {
          headers,
          responseType: 'text' as 'json',
        }
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getLinkConvite(idConvite: any) {
    let httpOptions = {
      responseType: 'text' as 'json',
    };
    return this.http
      .get<any>(
        `${environment.API_BACK}/convite/get-link/${idConvite}`,
        httpOptions
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  criaConvite(obj: any) {
    let httpOptions = {
      responseType: 'text' as 'json',
    };
    return this.http
      .post<any>(
        `${environment.API_BACK}/convite/novo-usuario`,
        obj
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  sendCarrierInvitation(file: File) {
    let headers = new HttpHeaders();
    let formData = new FormData();
    formData.append('file', file);
    return this.http
      .post<any>(
        `${environment.API_BACK}/convite/novo-usuario/arquivo`,
        formData,
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          throw new Error(error);
        })
      );
  }

  removeInvitation(idInvitation: number) {
    let params = new HttpParams();
    params.set('idConvite', idInvitation);

    return this.http
      .delete<any>(`${environment.API_BACK}/convite/remove/${idInvitation}`)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          throw new Error(error);
        })
      );
  }
}
