import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';
import {
  carriers,
  headerTable,
  payments,
  profile,
  titleAccordion,
  validationCriteria,
} from '../../model/user-registration-shipper-permissions.model';
import { ConfigurationsShipperService } from '../configurations-shipper.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-my-user-form',
  templateUrl: './my-user-form.component.html',
  styleUrls: ['./my-user-form.component.scss'],
})
export class MyUserFormComponent implements OnInit {
  userForm: FormGroup;
  listTables = [profile, payments, carriers, validationCriteria];
  listHeaders = [headerTable];
  usuario = JSON.parse(localStorage.getItem('usuario')!);
  idUsuario: any;
  listTitle = titleAccordion;
  submitted = false;
  resultField: boolean = false;
  emptyField: boolean = true;
  options: any[] = [];
  obj: any;
  id: any;
  page: number = 0;
  size: number = 50;
  totalElements: number = 0;
  validEmail: boolean = false;
  shippersByUser: any[] = [];
  shippersAssociate: any[] = [];
  filtro: any = {};
  hidden: boolean = true

  emptyPassword: boolean = true;

  validLength: boolean = false;
  validNumber: boolean = false;
  validSpecialCharacter: boolean = false;
  validUpper: boolean = false;
  validLower: boolean = false;

  confirmPasswordValue: boolean = false;

  @ViewChild('userRef') userRef: ElementRef;
  @ViewChild('emailRef') emailRef: ElementRef;
  @ViewChild('phoneRef') phoneRef: ElementRef;
  @ViewChild('alcadaRef') alcadaRef: ElementRef;
  @ViewChild('userTypeRef') userTypeRef: NgSelectComponent;
  @ViewChild('passwordRef') passwordRef: ElementRef;
  @ViewChild('confirmPasswordRef') confirmPasswordRef: ElementRef;
  @ViewChild('shippersByUserRef') shippersByUserRef: NgSelectComponent;

  constructor(
    private fb: FormBuilder,
    private configShipperService: ConfigurationsShipperService,
    private route: ActivatedRoute,
    private _snackBar: SnackbarDefaultService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getAssociatedShippers();

    this.getUserType();
    this.checkIdToSetForm();
    if (this.id != undefined && this.id != null) {
      this.getUser();
    }
    this.getShippers();
  }

  get userFormControls() {
    return this.userForm.controls;
  }

  getUserType() {
    const paginatorObj = {
      page: this.page,
      size: this.size,
    };
    this.configShipperService
      .getTypeUser(paginatorObj)
      .pipe()
      .subscribe((result: any) => {
        return result.content.forEach((element: { tipo: any; id: any }) => {
          this.options.push({
            label: this.typeUser(element.tipo),
            value: element.id,
          });
        });
      });

  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['MustMatch']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ MustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  public submitForm() {
    this.userForm.markAllAsTouched();
    if (
      this.userForm.get('password')?.value !==
      this.userForm.get('confirmPassword')?.value
    ) {
      this._snackBar.openSnackBarWarning('As senhas não coincidem');
      return;
    }

    if(this.userForm.invalid){
      this._snackBar.openSnackBarWarning('Há campos que necessitam de atenção!')
      if(this.userForm.controls['name'].invalid){
        this.userRef.nativeElement.focus();
      }else if(this.userForm.controls['email'].invalid){
        this.emailRef.nativeElement.focus();
      } else if(this.userForm.controls['phoneNumber'].invalid){
        this.phoneRef.nativeElement.focus();
      } else if(this.userForm.controls['alcada'].invalid){
        this.alcadaRef.nativeElement.focus();
      } else if(this.userForm.controls['userType'].invalid){
        this.userTypeRef.focus();
      } else if(this.userForm.controls['password'].invalid){
        this.passwordRef.nativeElement.focus();
      } else if(this.userForm.controls['confirmPassword'].invalid){
        this.confirmPasswordRef.nativeElement.focus();
      } else if(this.userForm.controls['shippersByUser'].invalid){
        this.shippersByUserRef.focus();
      }
      return;
    }

    const usuario = {
      nome: this.userForm.get('name')?.value,
      email: this.userForm.get('email')?.value,
      senha: this.userForm.get('password')?.value,
      telefone: this.userForm
        .get('phoneNumber')
        ?.value.replace(/[(-)]/g, ''),
      clienteId: this.usuario.cliente.id,
      tipoUsuario: this.userForm.get('userType')?.value,
      alcada: this.userForm.get('alcada')?.value
    };
    this.configShipperService
      .createUser(usuario)
      .pipe(first())
      .subscribe({
        next: (result) => {
          const management = {
            empresa: this.userForm.get('shippersByUser')?.value,
            usuario: result.id,
          };
          this.includeInManagement(management, true);
        },
        error: (e) => {
          this._snackBar.openSnackBarError('Erro ao salvar usuario - ' + e.error);
        },
      });
  }


  public submitFormUpdate() {
    if(this.userForm.invalid){
      this._snackBar.openSnackBarWarning('Há campos que necessitam de atenção!')
      if(this.userForm.controls['name'].invalid){
        this.userRef.nativeElement.focus();
      }else if(this.userForm.controls['email'].invalid){
        this.emailRef.nativeElement.focus();
      } else if(this.userForm.controls['phoneNumber'].invalid){
        this.phoneRef.nativeElement.focus();
      } else if(this.userForm.controls['alcada'].invalid){
        this.alcadaRef.nativeElement.focus();
      } else if(this.userForm.controls['userType'].invalid){
        this.userTypeRef.focus();
      } else if(this.userForm.controls['password'].invalid){
        this.passwordRef.nativeElement.focus();
      } else if(this.userForm.controls['confirmPassword'].invalid){
        this.confirmPasswordRef.nativeElement.focus();
      } else if(this.userForm.controls['shippersByUser'].invalid){
        this.shippersByUserRef.focus();
      }
      return;
    }

    const usuario = {
      nome: this.userForm.get('name')?.value,
      email: this.userForm.get('email')?.value,
      telefone: this.userForm
        .get('phoneNumber')
        ?.value.replace(/[\\-]/g, ''),
      clienteId: this.usuario.cliente.id,
      tipoUsuario: this.userForm.get('userType')?.value,
      alcada: this.userForm.get('alcada')?.value,
      id: this.id
    };
    this.configShipperService
      .atualizaPerfil(usuario)
      .pipe(first())
      .subscribe({
        next: () => {
          const management = {
            empresa: this.userForm.get('shippersByUser')?.value,
            usuario: this.id,
          };
          this.includeInManagement(management, false);
        },
        error: (e) => {
          this._snackBar.openSnackBarError('Erro ao salvar usuario - ' + e.error);
        },
      });
  }


  public includeInManagement(management: any, novo:boolean) {
    this.configShipperService
      .createUserManagement(management)
      .pipe(first())
      .subscribe({
        next: (result: any) => {
          this.redirect();
          if(novo){
            this._snackBar.openSnackBarSuccess('Usuário cadastrado com sucesso');
          }
          if(!novo){
            this._snackBar.openSnackBarSuccess('Usuário alterado com sucesso');
          }
        },
        error: (error) => {
          this._snackBar.openSnackBarError(
            'Erro ao incluir embarcador na gestao'
          );
        }
  });
  }



  public checkEmail() {
    this.validEmail = false;
    const email = this.userForm.get('email')?.value;
    if (email) {
      if (email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/)) {
        this.validEmail = true;
      } else {
        this.validEmail = false;
      }
    }

    return this.validEmail;
  }

  validFieldsForm(field: string) {
    this.resultField = false;
    this.emptyField = true;

    if (
      this.userForm.get(field)?.value === '' &&
      this.userForm.get(field)?.touched &&
      this.userForm.get(field)?.invalid
    ) {
      this.resultField = true;
      this.emptyField = true;
    } else if (
      this.userForm.get(field)?.value !== '' &&
      this.userForm.get(field)?.valid &&
      this.userForm.get(field)?.touched
    ) {
      this.resultField = true;
      this.emptyField = false;
    }

    return this.resultField;
  }


  public getUser() {
    const paginatorObj = {
      id: this.route.snapshot.params['id'],
    };

    this.configShipperService
      .getUser(paginatorObj)
      .pipe()
      .subscribe((result: any) => {
        let obj = {
          nome: result.nome,
          telefone: result.telefone,
        };
        this.idUsuario = result.id;
        this.obj = obj;
        this.userForm.get('name')!.setValue(result.nome);
        this.userForm.get('email')!.setValue(result.email);
        this.userForm.get('phoneNumber')!.setValue(result.telefone);
        this.userForm.get('alcada')!.setValue(result.alcada);
        this.userForm.get('userType')!.patchValue(result.tipoUsuario.id);
        this.userForm.get('password')!.setValue(result.senha);
        this.userForm.get('confirmPassword')!.patchValue(result.senha);
      });

  }

  public typeUser(typeUser: string) {
    if (typeUser == 'admin') return 'admin';
    return 'padrão';
  }
  public async checkIdToSetForm() {
    let required = this.id ? null : [Validators.required]
    this.userForm = this.fb.group(
      {
        name: [this.id ? '' : null, [Validators.required]],
        email: [
          this.id ? '' : null,
          Validators.compose([Validators.required, Validators.email]),
        ],
        phoneNumber: [
          this.id ? this.usuario.telefone : null,
          [Validators.required],
        ],
        userType: [
          this.id ? this.usuario.tipoUsuario : null,
          [Validators.required],
        ],
        password: ['', required],
        confirmPassword: ['', required],
        shippersByUser: ['', required],
        alcada: [this.id ? this.usuario.alcada : null, Validators.required],
      },
    );

    this.userForm
    .get('password')
    ?.valueChanges.subscribe((value) => this.validFormatPassword(value));
  }

  validConfirmPassword() {
    const password = this.userForm.get('password')?.value;
    const confirmPassword = this.userForm.get('confirmPassword')?.value;

    if ((confirmPassword !== '' && password !== '') && confirmPassword !== password) {
      this.hidden = false
      this.confirmPasswordValue = false;
    } else if(password == '' || confirmPassword == '' ) {
      this.hidden = true
    }else if(confirmPassword == password) {
      this.confirmPasswordValue = true;
      this.hidden = true
    }

  }

  public redirect() {
    setTimeout(() => {
      this.router
        .navigate(['configurations-shipper/users'])
        .catch((err) => err);
    }, 2000);
  }

  public getShippers() {
    this.configShipperService
      .getShippers()
      .pipe()
      .subscribe((result: any) => {
        this.shippersByUser = result.content.map((dado: any) => ({
          label: dado.razaoSocial,
          value: dado.id,
          id: dado.id,
        }));
      });
  }

  public getAssociatedShippers() {
    const usuario = this.route.snapshot.params['id'];
    const paramsObj = {
      usuario,
    };
    this.configShipperService.getShipperByUser(paramsObj, null)
      .pipe(first())
      .subscribe((dados) => {
        const relatedShippersIds = dados.content?.map((shipper: any) => shipper.empresa.id);

        this.userForm.get('shippersByUser')!.setValue(relatedShippersIds);
    });
  }

  visiblePassword(event: any, elementId: string) {
    const { target } = event;
    const { checked } = target;
    const element = document.getElementById(elementId);
    element?.setAttribute('type', checked ? 'text' : 'password');
  }

  public validFormatPassword(password: string) {
    let confirmPassword = this.userForm.get('confirmPassword')?.value
    if (password == null || password === '') {
      this.validLength = false;
      this.validNumber = false;
      this.validSpecialCharacter = false;
      this.validUpper = false;
      this.validLower = false;
      return;
    }

    this.emptyPassword = false;

    this.validLength = password.length >= 8 && password.length <= 30;
    this.validNumber = !!RegExp(/\d/).exec(password);
    this.validSpecialCharacter = !!password.match(/[^a-zA-Z 0-9]+/g);
    this.validUpper = !!RegExp(/[A-Z]+/).exec(password);
    this.validLower = !!RegExp(/[a-z]+/).exec(password);

    if(password != null && confirmPassword != null){
      if(password == confirmPassword) this.confirmPasswordValue = true
    }
  }
}
