<div class="container-confirm">
  <mat-dialog-title>
    <h6 class="mt-2">{{ title }}</h6></mat-dialog-title
  >
  <mat-dialog-content>
    <div fxLayout="row wrap">
      <p *ngIf="!maxBid" class="description" [style.white-space]="'pre-line'">{{ description }}</p>
      <p *ngIf="maxBid" class="description">O valor do lance é igual ou superior a <strong style="color: red;">R$ 50.000,00</strong>. Deseja continuar?</p>
      <p *ngIf="lastModification" class="description mt-2">{{ lastModification }}</p>
      <p *ngIf="msgConfirm" class="description mt-2">{{ msgConfirm }}</p>
    </div>
  </mat-dialog-content>
  <div class="d-flex flex-row-reverse mt-3">
    <div mat-dialog-actions>
      <button mat-flat-button mat-dialog-close class="btn-grey" (click)="close()" id="btn-cancelar">
        Cancelar
      </button>
      <button mat-flat-button color="primary" cdkFocusInitial class="btn-green" (click)="confirm()" id="btn-confirmar">
        <span *ngIf="config">{{config}}</span>
        <span *ngIf="!config">Confirmar</span>     
      </button>
    </div>
  </div>
</div>
