import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SimplifiedOfferService } from 'src/app/shipper/simplified-offer/simplified-offer.service';
import { first, lastValueFrom, take } from 'rxjs';
import { MyInvitationsService } from 'src/app/shipper/my-invitations/my-invitations.service';
import { OfferConsultationService } from 'src/app/shipper/offer-consultation/offer-consultation.service';
import { SnackbarDefaultService } from '../services/snackbar-default.service';
import { DateRange } from '@angular/material/datepicker';

@Component({
  selector: 'app-dialog-attachment',
  templateUrl: './dialog-attachment.component.html',
  styleUrls: ['./dialog-attachment.component.scss'],
})
export class DialogAttachmentComponent {
  files: File[] = [];
  allowedExtensionImage: string[] = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'image/bmp',
  ];

  isImage: boolean = false;
  title: string = '';
  description: string = '';
  type: string = '';
  fileType: any;
  loading: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: SnackbarDefaultService,
    private service: SimplifiedOfferService,
    private dialogRef: MatDialogRef<DialogAttachmentComponent>,
    private shipperInvitationService: MyInvitationsService,
    private offerConsultationService: OfferConsultationService
  ) {
    this.title = this.data.title;
    this.description = this.data?.description;
    this.type = this.data?.type;
  }

  onSelect(event: any) {

    if(this.type != 'spot') {
      if (this.files.length > 0) {
        this.files.splice(this.files.indexOf(event), 1);
      }
    }

    if (this.type === 'orderCSV' || this.type === 'recipientCSV') {
      this.fileType = event.addedFiles[0].type;
      if (
        this.fileType === 'text/csv' ||
        this.fileType ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        this.files.push(...event.addedFiles);
      } else {
        this.files.splice(this.files.indexOf(event), 1);
        this._snackBar.openSnackBarError('Tipo de arquivo inválido!');
      }
    } else {
      this.files.push(...event.addedFiles);
    }

    for (const el of this.files) {
      this.isImage = this.allowedExtensionImage.includes(el.type);
    }
    return this.isImage;
  }

  public importFile() {
    this.loading = true
    if (this.files.length > 0) {
      if (this.type == 'carrierAttachment') {
        this.shipperInvitationService
          .sendCarrierInvitation(this.files[0])
          .pipe(first())
          .subscribe((data) => {
            if (!data.sucesso) {
              this._snackBar.openSnackBarErrorWithDurationAndLog(
                'Foram localizadas linhas com problema no arquivo enviado. Favor clicar no link de log para verificar o problema:',
                300000,
                `https://plataformadefretes-log.s3.amazonaws.com/${data.linkArquivoLog}`
              );
              this.dialogRef.close(true);
            } else {
              this._snackBar.openSnackBarSuccess(
                'Arquivo importado com sucesso.'
              );
              this.loading = false
              this.dialogRef.close(true);
            }

            this.loading = false
            this.dialogRef.close();
          });
      } else if (this.type == 'orderCSV') {
        this.offerConsultationService
          .importOrderCsv(this.files[0])
          .pipe(take(1))
          .subscribe({
            next: (data) => {
              if (!data.sucesso) {

                this._snackBar.openSnackBarErrorWithDurationAndLog(
                  'Foram localizadas linhas com problema no arquivo enviado. Favor clicar no link de log para verificar o problema:',
                  500000,
                  `https://plataformadefretes-log.s3.amazonaws.com/${data.linkArquivoLog}`
                );
                this.dialogRef.close(true);
              } else {
                this._snackBar.openSnackBarSuccess(
                  'Arquivo importado com sucesso'
                );
                this.loading = false
                this.dialogRef.close(true);
              }
            },
            error: (error) => {
              this._snackBar.openSnackBarError('Erro ao importar arquivo');
            },
          });
      } else if (this.type == 'recipientCSV') {
        this.importRecipientCSV()
      } else if (this.type == 'companyCSV') {
        this.importCompanyCSV()
      }  
      else {
        this.salvaAnexos()
      }
    } else {
      this._snackBar.openSnackBarWarning(
        'Não há arquivos para serem enviados.'
      );
    }
  }

  importRecipientCSV() {
    this.offerConsultationService
      .importRecipientCsv(this.files[0])
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          if (!data.sucesso) {
            this._snackBar.openSnackBarErrorWithDurationAndLog(
              'Foram encontrados problemas no arquivo enviado. Favor clique no link de log para verificar o problema:',
              50000000,
              `https://plataformadefretes-log.s3.amazonaws.com/${data.linkArquivoLog}`
            );
            this.dialogRef.close(true);
          } else {
            this._snackBar.openSnackBarSuccess(
              'Arquivo importado com sucesso'
            );
            this.loading = false
            this.dialogRef.close(true);
          }
        },
        error: (error) => {
          this._snackBar.openSnackBarError('Erro ao importar arquivo');
          this.dialogRef.close(true);
        },
      });
  }

  importCompanyCSV() {
    this.offerConsultationService
      .importCompanyCsv(this.files[0])
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          if (!data.sucesso) {
            this._snackBar.openSnackBarErrorWithDurationAndLog(
              'Foram encontrados problemas no arquivo enviado. Favor clique no link de log para verificar o problema:',
              50000000,
              `https://plataformadefretes-log.s3.amazonaws.com/${data.linkArquivoLog}`
            );
            this.dialogRef.close(true);
          } else {
            this._snackBar.openSnackBarSuccess(
              'Arquivo importado com sucesso'
            );
            this.loading = false
            this.dialogRef.close(true);
          }
        },
        error: (error) => {
          this._snackBar.openSnackBarError('Erro ao importar arquivo');
          this.dialogRef.close(true);
        },
      });
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  salvaAnexos() {
    let listFiles: any[] = [];

    if (this.type == 'spot') {
      let fileUploadPromises = this.files.map(file => 
        lastValueFrom(this.service.salvaAnexo(file).pipe(first())).then(data => {
          return { link: data, name: file.name };
        }).catch(error => {
          this._snackBar.openSnackBarError('Erro ao importar arquivo ' + file.name);
          throw error;
        })
      );
  
      Promise.all(fileUploadPromises).then(results => {
        listFiles = results;
        this.loading = false;
        this.dialogRef.close(listFiles);
      }).catch(() => {
        this.loading = false;
        this.dialogRef.close();
      });
    } else {
      this.service.salvaAnexo(this.files[0]).pipe(first()).subscribe({
        next: (data) => {
          this._snackBar.openSnackBarSuccess('Arquivo ' + this.files[0].name + ' importado com sucesso');
          this.loading = false;
          this.dialogRef.close(data);
        },
        error: (error) => {
          this._snackBar.openSnackBarError('Erro ao importar arquivo ' + this.files[0].name);
          this.loading = false;
          this.dialogRef.close();
        },
      });
    }
  }
}
