import { environment } from './../../../environments/environment';
import {
  Component,
  DoCheck,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-private-layout',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss'],
})
export class PrivateLayoutComponent implements OnInit, DoCheck {
  routePath: string = '';

  hideNavItems: boolean = false;
  pageTitle: string = '';
  componentRoutes: Array<any> = [];
  isHome: boolean = false;
  lastPath: string = '';
  visibleRecipients = environment.RECIPIENTS
  isAdmin: boolean = false;
  menuRoutes: Array<any> = [];
  user: any

  constructor(private router: Router, private route: ActivatedRoute, private utilsService: UtilsService,) {
    this.router.events.subscribe((value) => {
      if (!(value instanceof NavigationEnd || value instanceof Scroll)) return;

      this.configureTitle(route.snapshot.data);
    });
  }

  ngOnInit(): void {
    this.routePath = window.location.pathname;
    this.user = JSON.parse(localStorage.getItem('usuario')!)
    this.isAdmin = this.user?.tipoUsuario == 1
  }

  ngDoCheck(): void {
    this.routePath = window.location.pathname;
    this.lastPath = this.routePath.split('/').pop();
    this.user = JSON.parse(localStorage.getItem('usuario')!)
    this.isAdmin = this.user?.tipoUsuario == 1
  }

  public checkDigitPath(path: string) {
      if (!isNaN(Number(path))) {
        this.hideNavItems = true;
      }
  }

  configureTitle(data: any) {
    let menuitems: any[] = [];
    menuitems = data?.menuitems || [];
  
    const currentPaths = this.router.url
      .split('/')
      .filter((s: string) => s); // Divide a URL em partes e remove valores vazios
  
    // Inicialização padrão
    this.hideNavItems = !menuitems.length || false;
    this.isHome = false;
    this.componentRoutes = [];
  
    // Inicializa o caminho acumulado
    let accumulatedPath = '';
    let skipFirstSegment = false;
  
    // A primeira parte (configurations-shipper) deve ser ignorada no breadcrumb
    if(currentPaths[0] !== 'portal') {
      skipFirstSegment = true;
    }
      // Processa os itens de menu
  if (menuitems.length > 0) {
    this.menuRoutes = menuitems.map((mi: any) => ({
      ...mi,
      items: mi.items.filter((i: any) => !i?.hidden),
    }));
  }
    let menuItem: any = null;

    for (let i = 0; i < currentPaths.length; i++) {
      const segment = currentPaths[i];
      accumulatedPath += `/${segment}`;
  
      // Verifica o dígito do caminho
      this.checkDigitPath(segment);
  
      // Se a flag de ignorar o primeiro segmento for verdadeira, salta
      if (skipFirstSegment) {
        skipFirstSegment = false;
        continue;
      }
  
      // Tenta encontrar um item no menu que corresponda ao segmento
      
      for (const mi of menuitems) {
        menuItem = mi.items.find((item: any) => item.path === segment);
        if (menuItem) break;
      }
  
      if (menuItem) {
        // Adiciona um breadcrumb com o label do menu item
        this.componentRoutes.push({
          label: menuItem.breadcrumb || menuItem.label || '',
          path: accumulatedPath,
        });
        this.defineTitle(i, menuItem, menuItem.label || '');
      } else {
        // Caso seja um parâmetro dinâmico (ID), tratamos de forma especial
        const paramPattern = /^[0-9a-zA-Z-_]+$/;
        if (paramPattern.test(segment)) {
          // Se o último breadcrumb é dinâmico (um ID), procuramos a label associada
          if (this.componentRoutes.length > 0) {
            const lastRoute = this.componentRoutes[this.componentRoutes.length - 1];
  
            // Atualiza o path do último item no breadcrumb
            lastRoute.path += `/${segment}`;
  
            // Se encontrarmos uma label dinâmica, a aplicamos no último breadcrumb
            lastRoute.label = lastRoute.label || segment; // Caso não tenha, usa o ID como label
          } else {
            // Se o primeiro item for um parâmetro dinâmico
            this.componentRoutes.push({
              label: segment, // Inicializa a label como o próprio ID
              path: accumulatedPath,
            });
          }
        }
      }

    }
    
    // Verifica se está na home
    if (currentPaths.length === 1 && (currentPaths[0] === 'shipper' || currentPaths[0] === 'carriers')) {
      this.hideNavItems = true;
      this.isHome = true;
    }
    
    if (!menuItem) {
      this.hideNavItems = true;
    } else {
      this.hideNavItems = menuItem.hideNav || false;
      this.isHome = menuItem.home || false;

    }
  }
  
  defineTitle(i: any, m: any, label: any){
    if (i >= 2) {
      if (m.breadcrumb) {
        this.pageTitle = m.breadcrumb;
      } else {
        this.pageTitle = label;
      }
    } else if (i < 2 && m.breadcrumb) {
      this.pageTitle = m.breadcrumb;
    }
  }

  removeAccentuationForID(text: string){
    return this.utilsService.removeAccentuationForID(text)
  }

}
