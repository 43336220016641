export const environment = {
  production: true,
  AWS_S3_ANEXO: 'https://anexos.plataformadefretes.com.br/',
  AWS_S3_CSV: 'https://csv.plataformadefretes.com.br/',
  AWS_S3_ASSETS: 'https://assets.plataformadefretes.com.br/',

  API_BACK: 'https://utilities.homologacao.plataformadefretes.com.br/leilao',
  API_LOGIN:
    'https://utilities.homologacao.plataformadefretes.com.br/loginleilao',
  URL_FRONT: 'https://homologacao.plataformadefretes.com.br/',
  API_FILTER: 'https://utilities.homologacao.plataformadefretes.com.br/filter',
  COOKIE_DOMAIN: 'plataformadefretes.com.br',
  API_IA_SUGESTAO_FRETE:
    'https://gor97u4t1j.execute-api.us-east-2.amazonaws.com/dev/inference',
    API_PORTAL_TRANSP: 'https://utilities.homologacao.plataformadefretes.com.br/integrador',

  mapbox: {
    accessToken: 'pk.eyJ1IjoiZ2tvcGx1cyIsImEiOiJja2Z6b2xvZHQwMGV1MndxbTY4YW1xdWZzIn0.UYHq0dF9Rz7XFWFiL-iUCQ'
  },

  DASHBOARD: true,
  PAYMENTS: true,
  RECIPIENTS: true,
  AMBIENTE: 'homolog',
  PORTAL: true
};
